import { render, staticRenderFns } from "./MenuMobileOld.vue?vue&type=template&id=496b03cc"
import script from "./MenuMobileOld.vue?vue&type=script&lang=js"
export * from "./MenuMobileOld.vue?vue&type=script&lang=js"
import style0 from "./MenuMobileOld.vue?vue&type=style&index=0&id=496b03cc&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../nodevenv/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/16/lib/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubCategory: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/SubCategory.vue').default,SubCategoryLookBook: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/SubCategoryLookBook.vue').default,SubCategoryMark: require('/home/jeanmarcphilippe/public_html/jeanmarcphilippe.co.uk/shop.digipart.fr/components/SubCategoryMark.vue').default})
